import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Table,
  Select,
  DatePicker,
  Divider,
  Tooltip,
  Space,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  AuditOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { API, BEARER } from '../../constant';
import ItemListDisplay from '../../components/ItemList/ItemListDispaly';
import { getToken } from '../../helpers';
import { CSVLink } from 'react-csv';
import {
  useNavigate,
  Link,
  useSearchParams,
  useLocation,
} from 'react-router-dom';
import { getMonthNameFromStringDate, dateFormat } from '../../utils/dateUtils';
import {
  fabricLiabilityStatusArray,
  toProperCase,
  tnaStatusOptionArray,
  shipmentStatusOptionArray,
} from '../../utils/generic';

import { getUserDetails } from '../../helpers';
import moment from 'moment';

const userRolesAllowedForAttributes = {
  edit: {
    designAttribute: [1000, 1001, 1003],
    merchAttribute: [1000, 1004, 1002],
    planningAttribute: [1000, 1007, 1008],
  },
  view: {},
};

const launchNameFilterFunciton = (value, searchText) => {
  let flag = false;
  value?.launchDetails?.forEach((launch) => {
    console.log(
      launch?.launchName,
      'value - ',
      launch?.launchName
        ?.toLowerCase()
        ?.includes(searchText?.trim()?.toLowerCase())
    );
    if (
      launch?.launchName
        ?.toLowerCase()
        ?.includes(searchText?.trim()?.toLowerCase())
    ) {
      flag = true;
    }
  });
  return flag;
};
const filterGlobalSearch = (Data, searchText, filterScope) => {
  let filteredDataTemp = [];
  if (filterScope == 'all') {
    filteredDataTemp = Data?.filter((value) => {
      console.log(value);
      return (
        value?.launchDrop
          ?.toLowerCase()
          ?.includes(searchText?.trim()?.toLowerCase()) ||
        value?.sku?.toLowerCase().includes(searchText?.trim().toLowerCase()) ||
        value?.month
          ?.toLowerCase()
          ?.includes(searchText?.trim()?.toLowerCase()) ||
        value?.productOptionType?.productCatName
          ?.toLowerCase()
          ?.includes(searchText?.trim()?.toLowerCase()) ||
        value?.object_status
          ?.toLowerCase()
          ?.includes(searchText?.trim()?.toLowerCase())
      );
    });
  } else if (filterScope == 'launchDrop') {
    filteredDataTemp = Data.filter((value) => {
      return value?.launchDrop
        ?.toLowerCase()
        ?.includes(searchText?.trim()?.toLowerCase());
    });
  } else if (filterScope == 'launch_masters') {
    filteredDataTemp = Data.filter((value) => {
      console.log('searchValue:', searchText, value);
      return launchNameFilterFunciton(value, searchText);
    });
  } else if (filterScope == 'month') {
    filteredDataTemp = Data.filter((value) => {
      console.log('searchValue:', searchText, value);
      return value?.month
        ?.toLowerCase()
        ?.includes(searchText?.trim()?.toLowerCase());
    });
  }

  console.log(filteredDataTemp, 'filtered data', filterScope, searchText);
  if (filteredDataTemp.length > 0) {
    return filteredDataTemp;
  } else {
    filteredDataTemp.push({
      key: 0,
      id: 0,
      month: 'Not Found',
      launchDrop: 'Not Found',
      quantity: 'Not Found',
      sku: 'Not Found',
      sleeve: 'Not Found',
      assumedMRP: 'Not Found',
      status: 'Not Found',
      new_Repeat: 'Not Found',
      productOptionType: {
        productCatName: 'Not Found',
        productCatRefId: 0,
      },
      launchDetails: [],
      indentSheetDetails: [],
      techpackDetails: [],
      fabricUtilizationDetails: [],
    });
    return filteredDataTemp;
  }
};

const ReceivingProductPendingList = ({ tabKey }) => {
  const tabName = tabKey;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bookingForm] = Form.useForm();
  const [editBookingData, setEditBookingData] = useState(null);
  const [supplierList, setSupplierList] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPageName = searchParams.get('pageName');
  const currentPage =
    (currentPageName === tabName && parseInt(searchParams.get('page'), 10)) ||
    1;

  const user = getUserDetails();
  console.log(user, '48');
  //  ------------delete--form-----
  {
    /* delete product with comment */
  }
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [cancelProductForm] = Form.useForm();
  const [editProductDeleteData, setEditProductDeleteData] = useState(null);
  //  ------------end-delete--form-----

  const [isLoading, setIsLoading] = useState(null);

  const authToken = getToken();
  const [Data, setData] = useState([]);

  const [filterInfo, setFilterInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState('');
  const [isReceivedQtyValid, setIsReceivedQtyValid] = useState(true);

  const [searchText, setSearchText] = useState('');
  const [filterScope, setFilterScope] = useState('all');

  const filterScopeOptionsArray = [
    'all',
    'launchDrop',
    'month',
    'launch_masters',
  ];

  const [alreadySelectedRows, setAlreadySelectedRows] = useState([]);
  console.log(location);
  // not used
  // let searchPLaunchName = searchParams?.get('launchName')
  //   ? searchParams.get('launchName')
  //   : null;
  // console.log(searchPLaunchName);

  const [saving, setSaving] = useState(false);

  const toCoreProductDetails = (state) => {
    navigate('/coreProductDetails', { state });
  };

  let data1 = [];

  const fetchData = async (token) => {
    setIsLoading(true);
    try {
      //  ${
      //     location?.state && `&filters[otb_record][id][$eq]=${location?.state}`
      //   }
      let currentDate = new Date();
      currentDate = moment(currentDate, 'YYYY-MM-DD').toISOString();
      console.log(currentDate);
      const response = await fetch(
        // `${API}/core-products?createdAt:desc&populate[0]=launch_masters&populate[1]=product_option_type&populate[2]=indent_sheets&populate[3]=techpacks&populate[fabrics][fields][0]=id`,
        `${API}/core-products?filters[$and][0][$or][0][object_status][$eq]=booked&filters[$and][0][$or][1][object_status][$eq]=receiving_due&filters[$and][0][$or][2][object_status][$eq]=receiving_pending&filters[$and][1][$or][0][tna][tentativeEndDate][$lt]=${currentDate}&filters[$and][1][$or][1][object_status][$eq]=receiving_pending&populate[launch_masters][populate]=true&populate[tna][populate]=true&populate[supplier_master][populate]=true&populate[product_option_type][populate]=true&populate[indent_sheets][populate]=true&populate[techpacks][populate]=true&populate[fabric_used_for_products][populate][0]=fabric&populate[fabric_used_for_products][populate][1]=supplier_master&populate[fabric_used_for_products][populate][2]=tna&populate[sub_color_lists][populate]=true&populate[fits_list][populate]=true&populate[size_list][populate]=true&populate[design_season][populate]=true&populate[launch_season][populate]=true&populate[silhouettes][populate]=true&populate[trends][populate]=true&populate[special_attributes][populate]=true&populate[color_family][populate]=true&populate[product_variants][populate]=true&populate[product_order_detail][populate][shipment_details][populate][tna]=true&populate[product_order_detail][populate][shipment_details][populate][supplier_master]=true&sort=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const responseData = await response.json();
      console.log(responseData, 'response check');
      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        // return;

        data1 = responseData?.data?.map((entry) => {
          let launchObj = [];
          if (entry?.attributes?.launch_masters?.data?.length > 0) {
            console.log(entry?.attributes?.launch_masters?.data);
            launchObj = entry?.attributes?.launch_masters?.data?.map(
              (launchMasterObject) => {
                console.log(launchMasterObject);
                return {
                  launchRefId: launchMasterObject?.id,
                  launchName: launchMasterObject?.attributes?.launchName,
                  launchCategory: launchMasterObject?.attributes?.launchCat,
                  launchMonth: launchMasterObject?.attributes?.launchMonth,
                  launchDrop: launchMasterObject?.attributes?.launchDrop,
                  launchDate: launchMasterObject?.attributes?.launchDate,
                };
              }
            );
          }

          let fabricUtilizedObjArray = [];
          if (entry?.attributes?.fabric_used_for_products?.data?.length > 0) {
            fabricUtilizedObjArray =
              entry?.attributes?.fabric_used_for_products?.data?.map(
                (fabricUtilized) => {
                  let fupId = fabricUtilized?.id;
                  // let availableQuantity =
                  //   fabricUtilized?.attributes?.availableQuantity;
                  let consumptionPerUnit =
                    fabricUtilized?.attributes?.consumptionPerUnit;
                  let utilizationQty =
                    fabricUtilized?.attributes?.utilizationQty;
                  let qualityPercentage =
                    fabricUtilized?.attributes?.qualityPercentage;
                  let fabricObject = {
                    fabricId: fabricUtilized?.attributes?.fabric?.data?.id,
                    fabricName:
                      fabricUtilized?.attributes?.fabric?.data?.attributes
                        ?.fabricName,
                  };
                  let supplierObject = {
                    supplierId:
                      fabricUtilized?.attributes?.supplier_master?.data?.id,
                    supplierName:
                      fabricUtilized?.attributes?.supplier_master?.data
                        ?.attributes?.supplierName,
                  };
                  let tnaObject = {
                    tnaId: fabricUtilized?.attributes?.tna?.data?.id,
                    tnaStatus:
                      fabricUtilized?.attributes?.tna?.data?.attributes?.status,
                    tnaTentativeDate:
                      fabricUtilized?.attributes?.tna?.data?.attributes
                        ?.tentativeEndDate,
                  };

                  return {
                    fupId,
                    consumptionPerUnit,
                    utilizationQty,
                    qualityPercentage,
                    fabricObject,
                    supplierObject,
                    tnaObject,
                  };
                }
              );
          }

          let indentSheetsObjArray = [];
          if (entry?.attributes?.indent_sheets?.data?.length > 0) {
            console.log(entry?.attributes?.indent_sheets?.data);
            indentSheetsObjArray = entry?.attributes?.indent_sheets?.data?.map(
              (indentSheetObject) => {
                console.log(indentSheetObject);
                return {
                  indentRefId: indentSheetObject?.id,
                  indentReffName:
                    indentSheetObject?.attributes?.Indent_file_reference_id,
                  indentRefLink:
                    indentSheetObject?.attributes?.Indent_file_link,
                  indentDate: indentSheetObject?.attributes?.indent_file_date,
                  indentType: indentSheetObject?.attributes?.indent_type,
                };
              }
            );
          }

          let techpacksObjArray = [];
          if (entry?.attributes?.techpacks?.data?.length > 0) {
            console.log(entry?.attributes?.indent_sheets?.data);
            techpacksObjArray = entry?.attributes?.techpacks?.data?.map(
              (techpackObj) => {
                console.log(techpackObj);
                return {
                  techpackRefId: techpackObj?.id,
                  techpackReffName:
                    techpackObj?.attributes?.techpack_file_reference_id,
                  techpackRefLink: techpackObj?.attributes?.techpack_file_link,
                  techpackDate: techpackObj?.attributes?.techpack_file_date,
                  techpackSKU: techpackObj?.attributes?.sku,
                };
              }
            );
          }

          let subColorObjArray = [];
          if (entry?.attributes?.sub_color_lists?.data?.length > 0) {
            console.log(entry?.attributes?.sub_color_lists?.data);
            subColorObjArray = entry?.attributes?.sub_color_lists?.data?.map(
              (subColorObj) => {
                console.log(subColorObj);
                return {
                  subColorRefId: subColorObj?.id,
                  subColorName: subColorObj?.attributes?.subColorName,
                };
              }
            );
          }

          let fitObjArray = [];
          if (entry?.attributes?.fits_list?.data?.length > 0) {
            console.log(entry?.attributes?.fits_list?.data);
            fitObjArray = entry?.attributes?.fits_list?.data?.map((fitObj) => {
              console.log(fitObj);
              return {
                fitRefId: fitObj?.id,
                fitName: fitObj?.attributes?.fit_name,
              };
            });
          }

          let sizeObjArray = [];
          if (entry?.attributes?.size_list?.data?.length > 0) {
            console.log(entry?.attributes?.size_list?.data);
            sizeObjArray = entry?.attributes?.size_list?.data?.map(
              (sizeObj) => {
                console.log(sizeObj);
                return {
                  sizeRefId: sizeObj?.id,
                  sizeName: sizeObj?.attributes?.size_name,
                };
              }
            );
          }

          let specialAttrObjArray = [];
          if (entry?.attributes?.special_attributes?.data?.length > 0) {
            console.log(entry?.attributes?.special_attributes?.data);
            specialAttrObjArray =
              entry?.attributes?.special_attributes?.data?.map(
                (specialAttrObj) => {
                  console.log(specialAttrObj);
                  return {
                    specialAttrRefId: specialAttrObj?.id,
                    specialAttrName:
                      specialAttrObj?.attributes?.specialAttribute_name,
                  };
                }
              );
          }

          let silhouetteObjArray = [];
          if (entry?.attributes?.silhouettes?.data?.length > 0) {
            console.log(entry?.attributes?.silhouettes?.data);
            silhouetteObjArray = entry?.attributes?.silhouettes?.data?.map(
              (silhouetteObj) => {
                console.log(silhouetteObj);
                return {
                  silhouetteRefId: silhouetteObj?.id,
                  silhouetteName: silhouetteObj?.attributes?.silhouette_name,
                };
              }
            );
          }

          let trendObjArray = [];
          if (entry?.attributes?.trends?.data?.length > 0) {
            console.log(entry?.attributes?.trends?.data);
            trendObjArray = entry?.attributes?.trends?.data?.map((trendObj) => {
              console.log(trendObj);
              return {
                trendRefId: trendObj?.id,
                trendName: trendObj?.attributes?.trend_name,
              };
            });
          }

          let productOptionTypeObject = {};
          if (entry?.attributes?.product_option_type) {
            let productOptionType = entry?.attributes?.product_option_type;
            productOptionTypeObject = {
              //productCat -> product option type, example: shirt, crew, pants, etc
              productCatRefId: productOptionType?.data?.id,
              productCatName:
                productOptionType?.data?.attributes?.productCategoryName,
            };
          }

          let supplierDetails = {};
          if (entry?.attributes?.supplier_master) {
            let supplierObject = entry?.attributes?.supplier_master;
            supplierDetails = {
              //productCat -> product option type, example: shirt, crew, pants, etc
              supplierRefId: supplierObject?.data?.id,
              supplierName: supplierObject?.data?.attributes?.supplierName,
            };
          }

          let tnaDetails = {};
          if (entry?.attributes?.tna) {
            let tnaObject = entry?.attributes?.tna;
            tnaDetails = {
              //productCat -> product option type, example: shirt, crew, pants, etc
              tnaRefId: tnaObject?.data?.id,
              startDate: tnaObject?.data?.attributes?.startDate,
              tentativeEndDate: tnaObject?.data?.attributes?.tentativeEndDate,
              actualEndDate: tnaObject?.data?.attributes?.actualEndDate,
              comments: tnaObject?.data?.attributes?.comments,
              actionToBeTaken: tnaObject?.data?.attributes?.actionToBeTaken,
              type: tnaObject?.data?.attributes?.type,
            };
          }

          let productOrderDetails = {};
          if (entry?.attributes?.product_order_detail) {
            let productOrderDetailsObject =
              entry?.attributes?.product_order_detail;

            console.log(
              'product order details object ',
              productOrderDetailsObject,
              productOrderDetailsObject?.data?.id,
              entry?.attributes?.product_order_detail
            );

            const podShipmentDetails =
              productOrderDetailsObject?.data?.attributes?.shipment_details?.data?.map(
                (shipmentDetail) => {
                  return {
                    shipmentRefId: shipmentDetail?.id,
                    shipmentQuantity:
                      shipmentDetail?.attributes?.shipment_quantity,
                    shipment_type: shipmentDetail?.attributes?.shipment_type,
                    // supplierDetail: shipmentDetail?.attributes?.supplier_master,
                    tnaDetails: {
                      tnaId: shipmentDetail?.attributes?.tna?.data?.id,
                      startDate:
                        shipmentDetail?.attributes?.tna?.data?.attributes
                          ?.startDate,
                      tentativeEndDate:
                        shipmentDetail?.attributes?.tna?.data?.attributes
                          ?.tentativeEndDate,
                      actualEndDate:
                        shipmentDetail?.attributes?.tna?.data?.attributes
                          ?.actualEndDate,
                      status:
                        shipmentDetail?.attributes?.tna?.data?.attributes
                          ?.status,
                    },
                    supplierDetails: {
                      supplierRefId:
                        shipmentDetail?.attributes?.supplier_master?.data?.id,
                      supplierName:
                        shipmentDetail?.attributes?.supplier_master?.data
                          ?.attributes?.supplierName,
                    },
                  };
                }
              );

            productOrderDetails = {
              //productCat -> product option type, example: shirt, crew, pants, etc
              podRefID: productOrderDetailsObject?.data?.id,
              totalOrderQty:
                productOrderDetailsObject?.data?.attributes
                  ?.total_order_quantity,
              totalReceivedQty:
                productOrderDetailsObject?.data?.attributes?.received_qty,
              remainingQty:
                productOrderDetailsObject?.data?.attributes?.remaining_qty,
              podShipmentDetails: podShipmentDetails,
            };

            console.log('product order details modified', productOrderDetails);
          }

          let designSeasonObj = {};
          if (entry?.attributes?.design_season) {
            let designSeason = entry?.attributes?.design_season;
            console.log(designSeason);
            designSeasonObj = {
              designSeasonRefId: designSeason?.data?.id,
              designSeasonName: designSeason?.data?.attributes?.season_id,
            };
          }

          let launchSeasonObj = {};
          if (entry?.attributes?.launch_season) {
            let launchSeason = entry?.attributes?.launch_season;
            console.log(launchSeason);
            launchSeasonObj = {
              launchSeasonRefId: launchSeason?.data?.id,
              launchSeasonName: launchSeason?.data?.attributes?.season_id,
            };
          }

          let colorFamilyObj = {};
          if (entry?.attributes?.color_family) {
            let colorFamily = entry?.attributes?.color_family;
            console.log(colorFamily);
            colorFamilyObj = {
              colorFamilyRefId: colorFamily?.data?.id,
              colorFamilyName: colorFamily?.data?.attributes?.colorName,
            };
          }

          let productVariantArray = [];
          if (entry?.attributes?.product_variants?.data?.length > 0) {
            console.log(entry?.attributes?.product_variants?.data);
            productVariantArray =
              entry?.attributes?.product_variants?.data?.map(
                (productVariantObject) => {
                  console.log(productVariantObject);
                  return {
                    variantRefId: productVariantObject?.id,
                    variantSKU: productVariantObject?.attributes?.variant_sku,
                    variantSize: productVariantObject?.attributes?.size,
                    variantSubColor:
                      productVariantObject?.attributes?.sub_color,
                    variantFit: productVariantObject?.attributes?.fit,
                    variantCost: productVariantObject?.attributes?.cost,
                    variantMrp: productVariantObject?.attributes?.mrp,
                  };
                }
              );
          }

          return {
            key: entry?.id,
            id: entry?.id,
            month: entry?.attributes?.month,
            launchDrop: entry?.attributes?.launchDrop,
            category: productOptionTypeObject?.productCatName,
            quantity: entry?.attributes?.qty,

            sleeve: entry?.attributes?.Sleeve,
            amrp: entry?.attributes?.amrp,
            assumedMRP: entry?.attributes?.assumedMRP,
            fashionCore: entry?.attributes?.fashionCore,
            description: entry?.attributes?.description,
            collection: entry?.attributes?.collection,
            sku: entry?.attributes?.sku,
            new_Repeat: entry?.attributes?.new_Repeat,
            proj_disc: entry?.attributes?.proj_disc,
            cogs: entry?.attributes?.cogs,
            total_cogs: entry?.attributes?.total_cogs,
            cogs_percentage: entry?.attributes?.cogs_percentage,
            pp_slab: entry?.attributes?.pp_slab,
            asp: entry?.attributes?.asp,
            quality: entry?.attributes?.quality,
            arv: entry?.attributes?.arv,
            total_arv: entry?.attributes?.total_arv,
            target_cogs: entry?.attributes?.target_cogs,
            lifeOccasion: entry?.attributes?.lifeOccasion,
            weight: entry?.attributes?.weight,
            gsm: entry?.attributes?.gsm,
            attributionCategory: entry?.attributes?.attributionCategory,
            pattern: entry?.attributes?.pattern,
            knit_woven: entry?.attributes?.knit_woven,
            freshness: entry?.attributes?.freshness,

            launchDetails: launchObj,
            productOptionType: productOptionTypeObject,
            indentSheetDetails: indentSheetsObjArray,
            techpackDetails: techpacksObjArray,
            status: entry?.attributes?.status,
            object_status: entry?.attributes?.object_status,
            object_status_no: entry?.attributes.object_status_no,
            fabricUtilizationDetails: fabricUtilizedObjArray,
            supplier_master: supplierDetails,
            tna: tnaDetails,
            productOrderDetails: productOrderDetails,
            // other object fields
            designSeason: designSeasonObj,
            launchSeason: launchSeasonObj,
            colorFamily: colorFamilyObj,
            colorVariantDetails: subColorObjArray,
            sizeVariantDetails: sizeObjArray,
            fitVariantDetails: fitObjArray,
            silhouetteDetails: silhouetteObjArray,
            trendDetails: trendObjArray,
            specialAttributeDetails: specialAttrObjArray,
            productVariantDetails: productVariantArray,
          };
        });

        console.log('original Data:', responseData);
        console.log('current Data after modification:', data1);

        setData(filterGlobalSearch(data1, searchText, filterScope));
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchData(authToken);
    }
  }, []);

  useEffect(() => {
    console.log(searchText);
    if (searchText == '') {
      fetchData(authToken);
    } else {
      fetchData(authToken);
    }
  }, [searchText, filterScope]);

  useEffect(() => {
    console.log(editBookingData);
    //setting form data on change of booking edit data and booking Form reference
    bookingForm.setFieldsValue(editBookingData);
  }, [editBookingData, bookingForm]);

  {
    /* delete product with comment */
  }
  useEffect(() => {
    if (editProductDeleteData) {
      console.log(editProductDeleteData);
      cancelProductForm.setFieldsValue(editProductDeleteData);
    }
    //setting form data on change of booking edit data and booking Form reference
  }, [editProductDeleteData, cancelProductForm]);

  const showBookingModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    bookingForm.resetFields();
    setEditBookingData(null);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    bookingForm.resetFields();
    setEditBookingData(null);
    setIsModalOpen(false);
  };

  const fetchSupplierList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/suppler-masters?createdAt:desc`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          supplierName: entry.attributes.supplierName,
        };
      });

      setSupplierList(optionsData2);
      console.log('original Supplier master Data:', optionsData);
      console.log('modified Supplier master Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  {
    /* delete product with comment */
  }
  const showProductDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const handleProductDeleteOk = () => {
    cancelProductForm.resetFields();
    setEditProductDeleteData(null);
    setIsDeleteModalOpen(false);
  };
  const handleProductDeleteCancel = () => {
    cancelProductForm.resetFields();
    setEditProductDeleteData(null);
    setIsDeleteModalOpen(false);
  };

  const columns = [
    {
      key: 'month',
      title: 'Month',
      dataIndex: 'month',
      sortOrder: sortedInfo.columnKey === 'month' && sortedInfo.order,
    },
    {
      key: 'launchDrop',
      title: 'Launch Drop',
      dataIndex: 'launchDrop',
    },
    {
      key: 'launchDetails',
      title: 'Launch Name/Collection',
      render: (_, record) => {
        return record?.launchDetails[0]?.launchName || 'NA';
      },
    },
    {
      key: 'category',
      title: 'Category',
      dataIndex: 'category',
    },
    {
      key: 'sku',
      title: 'SKU',
      dataIndex: 'sku',
    },
    {
      key: 'supplier_master',
      title: 'Factory Name',
      render: (_, record) => {
        return record?.supplier_master?.supplierName;
      },
    },
    {
      key: 'mill_name',
      title: 'Mill info.',
      render: (_, record) => {
        return (
          record?.fabricUtilizationDetails[0]?.supplierObject?.supplierName ||
          'NA'
        );
      },
    },
    {
      key: 'quantity',
      title: 'Quantity',
      dataIndex: 'quantity',
    },
    {
      key: 'assumedMRP',
      title: 'Assumed MRP',
      dataIndex: 'assumedMRP',
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (_, record) => {
        switch (record?.object_status_no) {
          case 6:
            return 'Bookable';
            break;
          case 8:
            return 'Booked';
            break;
          default:
            return record?.object_status;
            break;
        }
        //filter feature to be done later.
      },
    },
    {
      key: 'action',
      title: 'Actions',

      render: (_, record) => {
        return (
          <div>
            <div className="ActionColumn">
              <EditOutlined
                style={{ color: 'black' }}
                onClick={() => Edit(record)}
              />
              <AuditOutlined
                style={{ color: 'green', marginRight: 8 }}
                onClick={() => bookProduct(record)}
              />
              <Button
                icon={
                  <DeleteOutlined style={{ color: 'red', marginRight: 8 }} />
                }
                onClick={() => Delete(record)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const deleteRecord = async (recordID) => {
    const data = {
      data: {
        actionOperation: 'delete',
        status: 'deleted',
        object_status: 'deleted',
        object_status_no: 15,
      },
    };
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/core-products/${recordID}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getToken()}`,
        },
      });
      const responseData = await response.json();
      console.log(responseData);

      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        message.success('Record Deleted Successfully');
        fetchData(getToken());
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  {
    /* delete product with comment */
  }
  // const Delete = (record) => {
  //   Modal.confirm({
  //     title: 'Are you sure you want to delete this',
  //     onOk: () => {
  //       deleteRecord(record.id);
  //     },
  //   });
  // };

  {
    /* delete product with comment */
  }
  const Delete = (record) => {
    setEditProductDeleteData({
      editDeleteId: record.id,
    });
    console.log(record);
    showProductDeleteModal();
  };

  const Edit = (record) => {
    toCoreProductDetails(record);
  };

  const SupplierList =
    supplierList?.map((supplier) => {
      return {
        label: supplier.supplierName,
        value: supplier.id,
      };
    }) || [];
  //

  const getPODShipmentDetailsArray = (shipmentsDetails) => {
    console.log('Shipment details -- full ', shipmentsDetails);
    return shipmentsDetails?.map((shipmentsDetail) => {
      console.log('Shipment details --  part', shipmentsDetail);
      return {
        shipId: shipmentsDetail?.shipmentRefId,
        shipment_qty: shipmentsDetail?.shipmentQuantity?.toString() || '0',
        shipment_type: shipmentsDetail?.shipment_type,
        supplier_master: shipmentsDetail?.supplierDetails?.supplierRefId,
        tnaStartDate:
          (shipmentsDetail?.tnaDetails?.startDate &&
            moment(shipmentsDetail?.tnaDetails?.startDate)) ||
          null,
        tnaTentativeDate:
          (shipmentsDetail?.tnaDetails?.tentativeEndDate &&
            moment(shipmentsDetail?.tnaDetails?.tentativeEndDate)) ||
          null,
        actualEndDate:
          (shipmentsDetail?.tnaDetails?.actualEndDate &&
            moment(shipmentsDetail?.tnaDetails?.actualEndDate)) ||
          null,
        tnaStatus: shipmentsDetail?.tnaDetails?.status,
        tnaId: shipmentsDetail?.tnaDetails?.tnaId,
      };
    });
  };

  const bookProduct = (record) => {
    fetchSupplierList(authToken);

    console.log(
      'product order details object --  receiving form',
      record?.productOrderDetails,
      record?.productOrderDetails?.podShipmentDetails
    );
    let podShipmentDetailsArray = getPODShipmentDetailsArray(
      record?.productOrderDetails?.podShipmentDetails
    );

    setEditBookingData({
      supplier_master: record?.supplier_master?.supplierRefId || null,
      // qty: record?.productOrderDetails?.totalOrderQty?.toString() || '0',
      qty: record?.quantity?.toString() || '0',
      // actualQtyReceived: record?.actualQtyReceived?.toString() || '0',
      actualQtyReceived:
        record?.productOrderDetails?.totalReceivedQty?.toString() || '0',
      remaining_qty:
        record?.productOrderDetails?.remainingQty?.toString() || '0',
      startDate:
        (record?.tna?.startDate && moment(record?.tna?.startDate)) || null,
      tentativeEndDate:
        (record?.tna?.tentativeEndDate &&
          moment(record?.tna?.tentativeEndDate)) ||
        null,
      actualEndDate:
        (record?.tna?.actualEndDate && moment(record?.tna?.actualEndDate)) ||
        null,
      comments: record?.tna?.comments,
      shipment_details: podShipmentDetailsArray,
      actionToBeTaken: record?.tna?.actionToBeTaken,
      type: record?.tna?.type,
      editId: record.id,
      tnaEditId: record?.tna?.tnaRefId,
      podEditId: record?.productOrderDetails?.podRefID,
    });
    console.log(record);
    console.log(moment(record?.tna?.startDate));

    showBookingModal();
  };

  const handleTableChange = (pagination, filters, sorter) => {
    console.log('in handle table change', pagination, filters, sorter);
    const { order, columnKey } = sorter;
    setFilterInfo(filters);
    setSortedInfo({ columnKey, order });
    const { current } = pagination;
    setSearchParams({ page: current, pageName: tabName });
  };

  const resetTable = () => {
    setSortedInfo({});
    setFilterInfo({});
    setSearchText('');
    setFilterScope('all');
    setAlreadySelectedRows([]);
    fetchData(authToken);
  };

  const handleChangeSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleFilterScopeChange = (value) => {
    console.log('setFilterScope', value);
    setFilterScope(value);
  }; // change in filter scope selected by user

  const getFilterOptionArray = (options) => {
    return options.map((option) => {
      return { value: option, label: option };
    });
  }; //

  const filterOptionArray = [...getFilterOptionArray(filterScopeOptionsArray)];

  const statusLookupObject = {
    active: 'Active',
    wip: 'WIP',
    completed: 'Completed',
    confirmed: 'Confirmed',
    fabric_complete: 'Fabric Complete',
    techpack_complete: 'Bookable',
    bookable: 'Bookable',
    booked: 'Booked',
    receiving_due: 'Receiving Due',
    receiving_pending: 'Receiving Pending',
    received: 'Received',
    postponed: 'Postponed',
    canceled: 'Canceled',
    dropped: 'Dropped',
    deleted: 'Deleted',
  };
  const getDisplayableStatus = (sys_status) => {
    return statusLookupObject[sys_status];
  };

  const getFormattedData = () => {
    const downloadData = Data;
    console.log(downloadData, 'downloading data...');

    let downloadDataFormatted = downloadData.map((row) => {
      console.log(row, 'downloading data row...');
      return {
        // Month: row.month,
        // 'Launch Drop': row.launchDrop,
        // Quantity: row.quantity,
        // 'Assumed MRP': row.assumedMRP,
        // 'Launch Name': row.launchDetails
        //   .map((launch) => launch?.launchName)
        //   .join(','),
        // 'Product Option Type': row.productOptionType?.productCatName,
        // 'Indent Attached': row.indentSheetDetails
        //   .map((indentSheetObj) => indentSheetObj?.indentRefLink)
        //   .join(','),
        // status: getDisplayableStatus(row.status),

        // ------------------

        // key: row.id,
        // id: row.id,
        Month: row.month,
        'Launch Drop': row.launchDrop,
        Category: row.productOptionType?.productCatName,
        SKU: row.sku,
        Quantity: row.quantity,
        'Assumed MRP': row.assumedMRP,

        sleeve: row.sleeve,
        // amrp: row.amrp,
        // fashionCore: row.fashionCore,
        // description: row.description,
        // collection: row.collection,

        new_Repeat: row.new_Repeat,
        // proj_disc: row.proj_disc,
        // cogs: row.cogs,
        // total_cogs: row.total_cogs,
        // cogs_percentage: row.cogs_percentage,
        // pp_slab: row.pp_slab,

        'Launch Name': row.launchDetails
          .map((launch) => launch?.launchName)
          .join(','),
        'Indent Attached': row.indentSheetDetails
          ?.map((indentSheetObj) => indentSheetObj?.indentRefLink)
          .join(','),
        'Techpack Attached': row.techpackDetails
          ?.map((techpackObj) => techpackObj?.techpackRefLink)
          .join(','),
        // productOptionType: productOptionTypeObject,
        // indentSheetDetails: indentSheetsObjArray,
        // techpackDetails: techpacksObjArray,
        'Fabric Utilized': row.fabricUtilizationDetails.map((fabricUtilObj) => {
          return `${fabricUtilObj.fabricObject.fabricName} (uses ${fabricUtilObj.consumptionPerUnit} per unit) `;
        }),
        // fabricUtilizationDetails: fabricUtilizedObjArray,

        // status: row.status,
        Status: getDisplayableStatus(row.object_status),
        // object_status_no: row.object_status_no,
      };
    });

    console.log(downloadDataFormatted, 'downloading data 2...');
    return downloadDataFormatted;
  };

  //   const confirmAllselectedRecords = async () => {
  //     console.log(alreadySelectedRows, 'confirm all selected records func');
  //     setIsLoading(true);
  //     try {
  //       const response = await fetch(
  //         `${API}/product-option/confirm-product-option`,
  //         {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //
  //             Authorization: `Bearer ${getToken()}`,
  //           },
  //           body: JSON.stringify({
  //             data: { selectedProductOptionRecords: alreadySelectedRows },
  //           }),
  //         }
  //       );
  //       const responseData = await response.json();
  //       console.log(responseData);

  //       if (responseData.error) {
  //         message.error('Something went wrong, please try again');
  //       } else {
  //         message.success('All Selected Records are confirmed Successfully');
  //         fetchData(getToken());
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       message.error('Error while fetching Data!');
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   const generateCoreProductForAllselectedRecords = async () => {
  //     console.log(
  //       alreadySelectedRows,
  //       'gen core product all selected records func'
  //     );
  //     setIsLoading(true);
  //     try {
  //       const response = await fetch(
  //         `${API}/product-option/generate-developmentProduct`,
  //         {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //
  //             Authorization: `Bearer ${getToken()}`,
  //           },
  //           body: JSON.stringify({
  //             data: { selectedProductOptionRecords: alreadySelectedRows },
  //           }),
  //         }
  //       );
  //       const responseData = await response.json();
  //       console.log(responseData);

  //       if (responseData.error) {
  //         message.error('Something went wrong, please try again');
  //       } else {
  //         message.success('completed core Product generation');
  //         fetchData(getToken());
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       message.error('Error while fetching Data!');
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  const addBookingDetails = async (data) => {
    console.log('Booking Data original:', data);
    // setSaving(true);
    const valuestna = {
      actualEndDate: data['actualEndDate'].format('YYYY-MM-DD'),
      comments: data.comments,
      actionToBeTaken: data.actionToBeTaken,
      status: 'complete',
      editId: data.editId,
      tnaEditId: data.tnaEditId,
    };

    const valuesCP = {
      object_status_no: 11,
      object_status: 'received',
      actualQtyReceived: parseFloat(data['actualQtyReceived']),
      qty: parseFloat(data['qty']),
      editId: data.editId,
      tnaEditId: data.tnaEditId,
    };

    data = { data: valuestna };

    console.log('Receiving valuestna:', valuestna);
    console.log('Receiving valuesCP:', valuesCP);

    // // return;
    console.log('Receiving data core product edit:', data.data.editId);
    console.log('Receiving data tna edit:', data.data.tnaEditId);

    if (data.data.tnaEditId) {
      console.log('Receiving edit id modal entity :', data.data.tnaEditId);
      try {
        const response = await fetch(`${API}/tnas/${valuesCP.tnaEditId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Booking Details.');
        } else {
          // message.success('Data saved successfully!');
          try {
            const response1 = await fetch(
              `${API}/core-products/${valuesCP.editId}`,
              {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',

                  Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify({
                  data: valuesCP,
                }),
              }
            );
            const responseData1 = await response1.json();
            console.log(responseData1, 'inside update core product status');
            if (responseData1.error) {
              message.error(
                'Unable to Update Product Status and Received Quantity.'
              );
            } else {
              message.success('Data saved successfully!');
              fetchData(authToken);
            }
          } catch (errorInside) {
            console.log(
              errorInside,
              'error while update status of Core product'
            );
          }

          handleOk();
          //   toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Adding Booking Details!');
      } finally {
        setSaving(false);
      }
    } else {
      // create a new record in the database table
      console.log('Receiving tna edit id modal entity :', data.data.tnaEditId);
      // try {
      //   const response = await fetch(`${API}/tnas/`, {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //
      //       Authorization: `Bearer ${authToken}`,
      //     },
      //     body: JSON.stringify(data),
      //   });
      //   const responseData = await response.json();
      //   console.log(responseData);
      //   if (responseData.error) {
      //     message.error('Unable to Add Booking Details.');
      //   } else {
      //     // message.success('Data saved successfully!');
      //     try {
      //       const response1 = await fetch(
      //         `${API}/core-products/${valuesCP.editId}`,
      //         {
      //           method: 'PUT',
      //           headers: {
      //             'Content-Type': 'application/json',
      //
      //             Authorization: `Bearer ${authToken}`,
      //           },
      //           body: JSON.stringify({
      //             data: valuesCP,
      //           }),
      //         }
      //       );
      //       const responseData1 = await response1.json();
      //       console.log(responseData1, 'inside update core product status');
      //       if (responseData1.error) {
      //         message.error('Unable to Update Product Status.');
      //       } else {
      //         message.success('Data saved successfully!');
      //         fetchData(authToken);
      //       }
      //     } catch (errorInside) {
      //       console.log(
      //         errorInside,
      //         'error while update status of Core product'
      //       );
      //     }

      //     handleOk();
      //     //   toPreviousPage();
      //   }
      // } catch (error) {
      //   console.error(Error);
      //   message.error('Error While Adding Booking Details!');
      // } finally {
      //   setSaving(false);
      // }
    }
  };

  const addBookingDetailsPodShipment = async (data) => {
    console.log(
      'Booking Data original:',
      data,
      data?.actualEndDate?.toISOString(),
      data?.actualEndDate?.format()
    );
    setSaving(true);
    // return;
    const valuestna = {
      actualEndDate: data['actualEndDate']?.format(),
      comments: data.comments,
      actionToBeTaken: data.actionToBeTaken,
      status: 'complete',
      editId: data.editId,
      tnaEditId: data.tnaEditId,
    };

    const valuesPOD = {
      received_qty: data?.shipment_details?.reduce(
        (receivedQty, shipmentDetails) =>
          receivedQty + parseFloat(shipmentDetails?.shipment_qty),
        0
      ),
      remaining_qty:
        data.qty -
        data?.shipment_details?.reduce(
          (receivedQty, shipmentDetails) =>
            receivedQty + parseFloat(shipmentDetails?.shipment_qty),
          0
        ),
      core_product: data.editId,
      shipment_details: data?.shipment_details,
      podEditId: data?.podEditId,
    };

    const valuesCP = {
      object_status_no: 10,
      object_status: 'receiving_pending',
      actualQtyReceived: parseFloat(data['actualQtyReceived']),
      qty: parseFloat(data['qty']),
      editId: data.editId,
      tnaEditId: data.tnaEditId,
      podEditId: data.podEditId,
    };

    data = { data: valuestna };

    console.log('Receiving valuestna:', valuestna);
    console.log('Receiving valuesCP:', valuesCP);
    console.log('Receiving valuesPOD:', valuesPOD);

    // return;
    console.log('Receiving data core product edit:', data.data.editId);
    console.log('Receiving data tna edit:', data.data.tnaEditId);

    if (data.data.tnaEditId) {
      console.log('Receiving edit id modal entity :', data.data.tnaEditId);
      try {
        const response = await fetch(`${API}/tnas/${valuesCP.tnaEditId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Booking Details.');
        } else {
          // message.success('Data saved successfully!');

          try {
            const responsePOD = await fetch(
              `${API}/product-order-details/${valuesCP?.podEditId}`,
              {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',

                  Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify({
                  data: valuesPOD,
                }),
              }
            );
            const responseDataPOD = await responsePOD.json();
            console.log(responseDataPOD, 'inside update product order details');
            if (responseDataPOD.error) {
              message.error('Unable to Update Product order details');
            } else {
              // message.success('Data saved successfully!');
              // fetchData(authToken);
            }
          } catch (errorInside) {
            console.log(
              errorInside,
              'error while update product order details'
            );
          }

          try {
            const response1 = await fetch(
              `${API}/core-products/${valuesCP.editId}`,
              {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',

                  Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify({
                  data: valuesCP,
                }),
              }
            );
            const responseData1 = await response1.json();
            console.log(responseData1, 'inside update core product status');
            if (responseData1.error) {
              message.error(
                'Unable to Update Product Status and Received Quantity.'
              );
            } else {
              message.success('Data saved successfully!');
              fetchData(authToken);
            }
          } catch (errorInside) {
            console.log(
              errorInside,
              'error while update status of Core product'
            );
          }
          // console.log('real mess...');

          //   toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Adding Booking Details!');
      } finally {
        setSaving(false);
        handleOk();
      }
    } else {
      // create a new record in the database table
      console.log('Receiving tna edit id modal entity :', data.data.tnaEditId);
      // try {
      //   const response = await fetch(`${API}/tnas/`, {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //
      //       Authorization: `Bearer ${authToken}`,
      //     },
      //     body: JSON.stringify(data),
      //   });
      //   const responseData = await response.json();
      //   console.log(responseData);
      //   if (responseData.error) {
      //     message.error('Unable to Add Booking Details.');
      //   } else {
      //     // message.success('Data saved successfully!');
      //     try {
      //       const response1 = await fetch(
      //         `${API}/core-products/${valuesCP.editId}`,
      //         {
      //           method: 'PUT',
      //           headers: {
      //             'Content-Type': 'application/json',
      //
      //             Authorization: `Bearer ${authToken}`,
      //           },
      //           body: JSON.stringify({
      //             data: valuesCP,
      //           }),
      //         }
      //       );
      //       const responseData1 = await response1.json();
      //       console.log(responseData1, 'inside update core product status');
      //       if (responseData1.error) {
      //         message.error('Unable to Update Product Status.');
      //       } else {
      //         message.success('Data saved successfully!');
      //         fetchData(authToken);
      //       }
      //     } catch (errorInside) {
      //       console.log(
      //         errorInside,
      //         'error while update status of Core product'
      //       );
      //     }

      //     handleOk();
      //     //   toPreviousPage();
      //   }
      // } catch (error) {
      //   console.error(Error);
      //   message.error('Error While Adding Booking Details!');
      // } finally {
      //   setSaving(false);
      // }
    }
  };

  // delete product with comment
  const fabricLiabilityStatusOptionList = fabricLiabilityStatusArray.map(
    (value) => {
      return {
        label: toProperCase(value),
        value: value,
      };
    }
  );

  const tnaStatusOptionList = tnaStatusOptionArray.map((value) => {
    return {
      label: toProperCase(value),
      value: value,
    };
  });

  const shipmentStatusOptionList = shipmentStatusOptionArray.map((value) => {
    return {
      label: toProperCase(value),
      value: value,
    };
  });

  const handleValuesChangeReceiving = (changedValues, allValues) => {
    console.log('Changed values:', changedValues);
    console.log('All values:', allValues);
    // setUpdatedValues({ ...changedValues, ...updatedValues });

    const derivedValues = {};
    if (changedValues) {
      if (changedValues?.actualQtyReceived) {
        const actualReceivedQty =
          parseFloat(changedValues?.actualQtyReceived) ||
          parseFloat(allValues?.actualQtyReceived) ||
          0;
        const orderedQty =
          parseFloat(changedValues?.qty) || parseFloat(allValues?.qty) || 0;
        const remainingOrderQty = orderedQty - actualReceivedQty;
        console.log('remaining qty...', remainingOrderQty);
        if (remainingOrderQty > 0) {
          setIsReceivedQtyValid(true);
          console.log('enter partial shipment details', remainingOrderQty);
        } else if (remainingOrderQty < 0) {
          // show error invalid actual quantity.
          setIsReceivedQtyValid(false);
        } else {
          //full quantity or order received.
          setIsReceivedQtyValid(true);
        }
      }
    }
  };

  // delete product with comment
  const deleteProductWithFabricLiabilityStatus = async (deleteFormData) => {
    console.log('product delete date :', deleteFormData);
    // return;

    let recordID = deleteFormData.editDeleteId;
    const data = {
      data: {
        actionOperation: 'delete',
        status: 'deleted',
        object_status: 'deleted',
        object_status_no: 15,
        fabric_liability_status: deleteFormData.fabric_liability_status,
        fabric_liability_remarks: deleteFormData.fabric_liability_remarks,
      },
    };
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/core-products/${recordID}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
      console.log(responseData);

      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        message.success('Record Deleted Successfully');
        fetchData(getToken());
        handleProductDeleteCancel();
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h2>Pending Products (Expected Date Exceeded)</h2>
      {/*   <Button
        onClick={() => {
          toCoreProductDetails();
        }}
      >
        Add
    </Button> */}

      {/* Table Section */}
      <div className="table">
        {/* Filter search Section */}
        <div className="filterContainer">
          <div className="tableSearchSection">
            <Select
              disabled={true}
              defaultValue="all"
              className="filterScopeSelect"
              onChange={handleFilterScopeChange}
              options={filterOptionArray}
            />

            <Input
              placeholder="Enter Search Text"
              onChange={handleChangeSearch}
              type="text"
              allowClear
              value={searchText}
            ></Input>
            <Button onClick={resetTable}>Reset</Button>
            <Button style={{ backgroundColor: 'greenyellow', color: 'white' }}>
              <CSVLink
                // data={Data}
                data={getFormattedData()}
                filename={
                  'Receiving-Pending-Product-list-' +
                  moment(new Date()).format('DD-MM-YYYY')
                }
              >
                Download
              </CSVLink>
            </Button>
          </div>
        </div>
        {isLoading ? (
          <Spin size="large" />
        ) : (
          <Table
            dataSource={Data}
            columns={columns}
            onChange={handleTableChange}
            pagination={{
              current: currentPage,
              pageSize: 10,
              total: Data.length,
              position: ['bottomCenter'],
            }}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: alreadySelectedRows,
              onChange: (keys) => {
                setAlreadySelectedRows(keys);
              },
              onSelect: (record) => {
                console.log(record, 'selected row ');
              },
              selections: [
                Table.SELECTION_NONE,
                Table.SELECTION_ALL,

                //   {
                //     key: 'confirmSelected',
                //     text: 'Confirm All Selected Rows',
                //     onSelect: (allkeys) => {
                //       console.log('confirmSelected');
                //       confirmAllselectedRecords();
                //     },
                //   },
                //   {
                //     key: 'ConfirmCreateCoreProduct',
                //     text: 'Confirm and Create Core Product',
                //     onSelect: (allkeys) => {
                //       console.log('confirmSelected');
                //       generateCoreProductForAllselectedRecords();
                //     },
                //   },
              ],
            }}
          />
        )}
      </div>
      <Modal
        title="Receiving Details"
        visible={isModalOpen}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        <Card className="addProduct_page_card">
          <Form
            form={bookingForm}
            layout="vertical"
            onFinish={addBookingDetailsPodShipment}
            onValuesChange={handleValuesChangeReceiving}
          >
            <Row gutter={[16, 16]}>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Factory"
                  name="supplier_master"
                  rules={[
                    {
                      required: true,
                      message: 'Factory is Required!',
                    },
                  ]}
                >
                  <Select
                    disabled={true}
                    showSearch
                    onSearch={(value) => {
                      // console.log('searching...', value);
                    }}
                    placeholder="Select "
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.trim().toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={SupplierList}
                  />
                </Form.Item>
              </Col>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Order Placed Date"
                  name="startDate"
                  rules={[
                    {
                      type: 'object',
                      required: true,
                      message: 'Order Placed Date is required',
                    },
                  ]}
                >
                  <DatePicker disabled={true} format={dateFormat} />
                </Form.Item>
              </Col>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Goods Expected Received Date"
                  name="tentativeEndDate"
                  rules={[
                    {
                      type: 'object',
                      required: true,
                      message: 'Goods Expected Received Date is required',
                    },
                  ]}
                >
                  <DatePicker disabled={true} format={dateFormat} />
                </Form.Item>
              </Col>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Ordered Quantity"
                  name="qty"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a valid quantity',
                      pattern: '^([0-9]{0,10})$',
                    },
                  ]}
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Comments"
                  name="comments"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input.TextArea rows={3} placeholder="Comments" />
                </Form.Item>
              </Col>
              {/** 
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Action To Be Taken"
                  name="actionToBeTaken"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input.TextArea rows={3} placeholder="Action To Be Taken" />
                </Form.Item>
              </Col>
              */}
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Actual Date of Receiving"
                  name="actualEndDate"
                  rules={[
                    {
                      type: 'object',
                    },
                  ]}
                >
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </Col>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Received Quantity"
                  name="actualQtyReceived"
                  validateStatus={isReceivedQtyValid ? '' : 'error'}
                  help={
                    isReceivedQtyValid
                      ? ''
                      : "Reveived Quantity can't be more than Ordered Quantity"
                  }
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a valid quantity',
                      pattern: '^([0-9]{0,10})$',
                    },
                  ]}
                >
                  <Input placeholder="Enter Actual Received Quantity" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Remaining Quantity"
                  name="remaining_qty"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a valid quantity',
                      pattern: '^([0-9]{0,10})$',
                    },
                  ]}
                >
                  <Input disable={true} />
                </Form.Item>
              </Col>

              <Divider orientation="left">Add Shipment Details</Divider>
              <Form.List name="shipment_details">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <>
                        <Space
                          key={key}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                            margin: '20px',
                          }}
                        >
                          <Form.Item
                            {...restField}
                            style={{ minWidth: '300px', marginRight: '15px' }}
                            label="Shipment Qty"
                            name={[name, 'shipment_qty']}
                            rules={[
                              {
                                required: true,
                                message: 'shipment Quantity is Required!',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            style={{ minWidth: '300px', marginRight: '15px' }}
                            label="Shipment Type"
                            name={[name, 'shipment_type']}
                            initialValue="full"
                          >
                            <Select
                              disabled={
                                !user.rolesAssigned.find((role) =>
                                  userRolesAllowedForAttributes.edit.merchAttribute.includes(
                                    role
                                  )
                                )
                              }
                              placeholder="Select "
                              options={shipmentStatusOptionList}
                            />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            style={{ minWidth: '300px', marginRight: '15px' }}
                            label="Factory"
                            name={[name, 'supplier_master']}
                            rules={[
                              {
                                required: true,
                                message: 'supplier info is Required!',
                              },
                            ]}
                          >
                            <Select
                              disabled={
                                !user.rolesAssigned.find((role) =>
                                  userRolesAllowedForAttributes.edit.merchAttribute.includes(
                                    role
                                  )
                                )
                              }
                              showSearch
                              onSearch={(value) => {
                                // console.log('searching...', value);
                              }}
                              placeholder="Select "
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                (option?.label ?? '')
                                  .toLowerCase()
                                  .includes(input.trim().toLowerCase())
                              }
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '')
                                  .toLowerCase()
                                  .localeCompare(
                                    (optionB?.label ?? '').toLowerCase()
                                  )
                              }
                              options={SupplierList}
                            />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            style={{ minWidth: '300px', marginRight: '15px' }}
                            label="shipment Start Date"
                            name={[name, 'tnaStartDate']}
                            rules={[
                              {
                                type: 'object',
                              },
                            ]}
                          >
                            <DatePicker
                              disabled={
                                !user.rolesAssigned.find((role) =>
                                  userRolesAllowedForAttributes.edit.merchAttribute.includes(
                                    role
                                  )
                                )
                              }
                              format={dateFormat}
                            />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            style={{ minWidth: '300px', marginRight: '15px' }}
                            label="Shipment Expected Date"
                            name={[name, 'tnaTentativeDate']}
                            rules={[
                              {
                                type: 'object',
                                message: 'Expected Date is required',
                              },
                            ]}
                          >
                            <DatePicker
                              disabled={
                                !user.rolesAssigned.find((role) =>
                                  userRolesAllowedForAttributes.edit.merchAttribute.includes(
                                    role
                                  )
                                )
                              }
                              format={dateFormat}
                            />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            style={{ minWidth: '300px', marginRight: '15px' }}
                            label="Shipment Timeline Status"
                            name={[name, 'tnaStatus']}
                            initialValue="active"
                          >
                            <Select
                              disabled={
                                !user.rolesAssigned.find((role) =>
                                  userRolesAllowedForAttributes.edit.merchAttribute.includes(
                                    role
                                  )
                                )
                              }
                              placeholder="Select "
                              options={tnaStatusOptionList}
                            />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            style={{ minWidth: '300px', marginRight: '15px' }}
                            label="Actual Shipment Recived Date"
                            name={[name, 'actualEndDate']}
                            rules={[
                              {
                                type: 'object',
                              },
                            ]}
                          >
                            <DatePicker
                              disabled={
                                !user.rolesAssigned.find((role) =>
                                  userRolesAllowedForAttributes.edit.merchAttribute.includes(
                                    role
                                  )
                                )
                              }
                              format={dateFormat}
                            />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            label="shipId"
                            hidden={true}
                            name={[name, 'shipId']}
                            initialValue={0}
                          >
                            <Input type="text" />
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            label="tnaType"
                            hidden={true}
                            name={[name, 'tnaType']}
                            initialValue="shipment"
                          >
                            <Select placeholder="Select Type">
                              <Select.Option value="product">
                                Product
                              </Select.Option>
                              <Select.Option value="fabric">
                                Fabric
                              </Select.Option>
                              <Select.Option value="pss">PSS</Select.Option>
                              <Select.Option value="shipment">
                                Shipment
                              </Select.Option>
                              <Select.Option value="photoshoot">
                                Photo Shoots
                              </Select.Option>
                            </Select>
                          </Form.Item>

                          <Form.Item
                            {...restField}
                            label="tnaId"
                            hidden={true}
                            name={[name, 'tnaId']}
                            initialValue={0}
                          >
                            <Input type="text" />
                          </Form.Item>

                          {user.rolesAssigned.find((role) =>
                            userRolesAllowedForAttributes.edit.merchAttribute.includes(
                              role
                            )
                          ) ? (
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          ) : null}
                        </Space>
                        <Divider orientation="left"></Divider>
                      </>
                    ))}

                    <Form.Item label="Add Shipment Details">
                      <Tooltip title="Add Shipment Utilized">
                        <Button
                          onClick={() => add()}
                          shape="circle"
                          icon={<PlusCircleOutlined />}
                          disabled={
                            !user.rolesAssigned.find((role) =>
                              userRolesAllowedForAttributes.edit.merchAttribute.includes(
                                role
                              )
                            )
                          }
                        />
                      </Tooltip>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Type"
                  name="type"
                  initialValue="product"
                  hidden={true}
                >
                  <Select placeholder="Select Type">
                    <Select.Option value="product">Product</Select.Option>
                    <Select.Option value="fabric">Fabric</Select.Option>
                    <Select.Option value="pss">PSS</Select.Option>
                    <Select.Option value="photoshoot">
                      Photo Shoots
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Form.Item label="EditId" hidden={true} name="editId">
                <Input type="text" />
              </Form.Item>
              <Form.Item label="tnaEditId" hidden={true} name="tnaEditId">
                <Input type="text" />
              </Form.Item>
              <Form.Item label="podEditId" hidden={true} name="podEditId">
                <Input type="text" />
              </Form.Item>
            </Row>
            <Button
              className="addProduct_save_btn"
              htmlType="submit"
              type="primary"
              size="large"
              loading={saving}
            >
              {saving ? <>Saving</> : 'Save'}
            </Button>
          </Form>
        </Card>
      </Modal>
      {/* delete product with comment */}
      <Modal
        title="Delete Product"
        visible={isDeleteModalOpen}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={handleProductDeleteOk}
        onCancel={handleProductDeleteCancel}
        width={1000}
      >
        <Card className="addProduct_page_card">
          <Form
            form={cancelProductForm}
            layout="vertical"
            onFinish={deleteProductWithFabricLiabilityStatus}
          >
            <Row gutter={[16, 16]}>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Select Fabric Status"
                  name="fabric_liability_status"
                  rules={[
                    {
                      required: true,
                      message: 'This is a required field',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    onSearch={(value) => {
                      // console.log('searching...', value);
                    }}
                    placeholder="Select "
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.trim().toLowerCase())
                    }
                    options={fabricLiabilityStatusOptionList}
                  />
                </Form.Item>
              </Col>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.fabric_liability_status !==
                    currentValues.fabric_liability_status
                  }
                >
                  {({ getFieldValue }) =>
                    getFieldValue('fabric_liability_status') === 'others' ? (
                      <Form.Item
                        label="Fabric Liability Remarks"
                        name="fabric_liability_remarks"
                        rules={[
                          {
                            type: 'string',
                          },
                        ]}
                      >
                        <Input.TextArea rows={3} placeholder="Comments" />
                      </Form.Item>
                    ) : null
                  }
                </Form.Item>
              </Col>

              <Form.Item label="EditId" hidden={true} name="editDeleteId">
                <Input type="text" />
              </Form.Item>
            </Row>
            <Button
              className="addProduct_save_btn"
              htmlType="submit"
              type="primary"
              size="large"
            >
              {saving ? (
                <>
                  <Spin size="small" /> Deleting
                </>
              ) : (
                'Delete'
              )}
            </Button>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};
export default ReceivingProductPendingList;
