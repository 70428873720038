import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Profile from './components/Profile/Profile';
// import SocialCards from "./components/SocialCards/SocialCards";
import Dashboard from './components/Dashboard/Dashboard';
import { getToken } from './helpers';
import SignIn from './pages/SignIn/SignIn';
import SignUp from './pages/SignUp/SignUp';
import RequiredAuth from './components/RequiredAuth';
import Unauthorized from './components/Unauthorized';
import ProductSpecification from './pages/ProductSpecification/ProductSpecification';

import AllProduct from './pages/AllProduct/AllProduct';
import DesignProductDetails from './pages/DesignProductDetails/DesignProductDetails';
import AllLaunchedProduct from './pages/AllLaunchedProduct/AllLaunchedProduct';
import MerchNewProd from './pages/MerchNewProd/MerchNewProd';
import MerchProdInProgress from './pages/MerchProdInProgress/MerchProdInProgress';
import MerchAllProduct from './pages/MerchAllProduct/MerchAllProduct';
import ProductDetailsView from './pages/ProductDetailsView/ProductDetailsView';
import CompleteProductView from './pages/CompleteProductView/CompleteProductView';
// import Layout from "./components/Layout/Layout";
import Missing from './components/Missing';
import CustomLayout from './components/CustomLayout';
import MerchEntry from './pages/MerchEntry/MerchEntry';

import PlanningNewProd from './pages/PlanningNewProd/PlanningNewProd';
import PlanningCompletedProducts from './pages/PlanningCompletedProducts/PlanningCompletedProducts';
import PlanningEntry from './pages/PlanningEntry/PlanningEntry';
import CreativeNewProd from './pages/CreativeNewProd/CreativeNewProd';
import CreativeCompletedProduct from './pages/CreativeCompletedProduct/CreativeCompletedProduct';
import CreativeEntry from './pages/CreativeEntry/CreativeEntry';

import EcomNewProduct from './pages/EcomNewProduct/EcomNewProduct';
import EcomCompletedProduct from './pages/EcomCompletedProduct/EcomCompletedProduct';
import LaunchDetails from './pages/LaunchDetails/LaunchDetails';
import EcomAllProduct from './pages/EcomAllProduct/EcomAllProduct';
import GenerateSheets from './pages/GenerateSheets/GenerateSheets';
import LaunchMaster from './pages/LaunchMaster/LaunchMaster';
import UplaodSheet from './pages/UplaodSheet/UplaodSheet';
import SessionManagement from './pages/SessionManagement/SessionManagement';
import PostponedProductsManagemnt from './pages/PostponedProductsManagemnt/PostponedProductsManagemnt';
import PostponeManagementPortal from './pages/PostponeManagementPortal/PostponeManagementPortal';
import TestPage from './pages/TestPage/TestPage';
import OTBPlan from './pages/OTBPlan/OTBPlan';
import CountryMaster from './pages/CountryMaster/CountryMaster';
import StateMaster from './pages/StateMaster/StateMaster';
import CityMaster from './pages/CityMaster/CityMaster';
import SupplierMaster from './pages/SupplierMaster/SupplierMaster';
import SupplierDetails from './pages/SupplierMaster/SupplierDetails';
import FabricMaster from './pages/FabricMaster/FabricMaster';
import OtbRecordList from './pages/OTBPlan/OtbRecordList';
import OTBDetails from './pages/OTBPlan/OTBDetails';
import ProductOptionList from './pages/ProductOption/ProductOptionList';
import ProductOptionDetails from './pages/ProductOption/ProductOptionDetails';
import CoreProductList from './pages/CoreProduct/CoreProductList';
import CoreProductDetails from './pages/CoreProduct/CoreProductDetailsNew';

import BookedProductList from './pages/BookedProductList/BookedProductList';
import ReceivingProductList from './pages/ReceivingProductList/ReceivingProductList';
import ReceivingProductPendingList from './pages/ReceivingProductPendingList/ReceivingProductPendingList';
import ReceivingProductReceivedList from './pages/ReceivingProductReceivedList/ReceivingProductReceivedList';
import ReceivingProductReorder from './pages/ReceivingProductReorder/ReceivingProductReorder';
import ProductManagement from './pages/ProductManagment/ProductManagment';
import BookableProductList from './pages/BookableProductList/BookableProductList';
import InboundProductManagement from './pages/InboundProductManagement/InboundProductManagement';
import IndentMaster from './pages/IndentMaster/IndentMaster';
import TechpackMaster from './pages/TechpackMaster/TechpackMaster';
import ProductOptionType from './pages/ProductOptionType/ProductOptionType';
import ColorMaster from './pages/ColorMaster/ColorMaster';
import VariantProductDetails from './pages/VariantProduct/VariantProductDetailsNew';
import GenerateBuyPlan from './pages/GenerateDoc/GenerateBuyPlan';
import GenerateSpecSheet from './pages/GenerateDoc/GenerateSpeckSheet';
import SizeMaster from './masters/Size/SizeMaster';
import PatternMaster from './masters/Pattern/PatternMaster';
import PPBracketMaster from './masters/PPBracket/PPBracketMaster';
import SpecialAttributeMaster from './masters/SpecialAttribute/SpecialAttributeMaster';
import SilhouetteMaster from './masters/Silhouette/SilhouetteMaster';
import UnitOfMeasurementMaster from './masters/UnitOfMeasurement/UnitOfMeasurementMaster';
import ProductSubCategoryMaster from './masters/ProductSubCategory/ProductSubCategoryMaster';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<SignIn />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/unauthorized" element={<Unauthorized />} />

        {/* Common WorkSpace*/}

        <Route
          element={
            <RequiredAuth
              allowedRoles={[
                100, 1000, 1001, 1003, 1002, 1004, 1007, 1008, 1009, 1010,
              ]}
            />
          }
        >
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />

          <Route path="/fullProductView" element={<CompleteProductView />} />
        </Route>

        {/* Design WorkSpace*/}
        <Route
          element={
            <RequiredAuth
              allowedRoles={[1000, 1001, 1003, 1002, 1004, 1007, 1008]}
            />
          }
        >
          <Route path="/productSpec" element={<ProductSpecification />} />

          <Route path="/allproduct" element={<AllProduct />} />

          <Route
            path="/DesignProductDetails"
            element={<DesignProductDetails />}
          />

          {/*Product Planning Phase */}
          {/*OTB Plan Activities  */}
          <Route path="otbplan" element={<OTBPlan />} />
          <Route path="OtbRecordList" element={<OtbRecordList />} />
          <Route path="OtbDetails" element={<OTBDetails />} />

          {/*Product Development/ confirmation Phase  */}
          {/*Product option Development Activities  */}
          <Route path="productOptionList" element={<ProductOptionList />} />
          <Route
            path="productOptionDetails"
            element={<ProductOptionDetails />}
          />

          {/*Product Development/ confirmation Phase  */}
          {/*Core Product Activities  */}
          <Route path="productManagement" element={<ProductManagement />} />
          <Route path="coreProductList" element={<CoreProductList />} />
          <Route path="coreProductDetails" element={<CoreProductDetails />} />

          {/*Booking Phase  */}
          <Route path="bookabeProducts" element={<BookableProductList />} />
          <Route path="bookedProducts" element={<BookedProductList />} />
          {/*<Route path="coreProductDetails" element={<CoreProductDetails />} /> */}
        </Route>

        <Route
          element={
            <RequiredAuth
              allowedRoles={[1000, 1001, 1003, 1002, 1004, 1007, 1008, 1010]}
            />
          }
        >
          {/* Receiving product management */}
          <Route
            path="inboundProductManagement"
            element={<InboundProductManagement />}
          />

          <Route path="receivingProducts " element={<ReceivingProductList />} />
          <Route
            path="receivingProductPending "
            element={<ReceivingProductPendingList />}
          />
          <Route
            path="receivingProductReceived "
            element={<ReceivingProductReceivedList />}
          />
          <Route
            path="receivingProductReorder"
            element={<ReceivingProductReorder />}
          />
        </Route>

        {/* Merch WorkSpace*/}
        <Route element={<RequiredAuth allowedRoles={[1000, 1002, 1004]} />}>
          <Route path="/merch-new-prod" element={<MerchNewProd />} />
          <Route path="/prod-in-progress" element={<MerchProdInProgress />} />
          <Route path="/merch-complete-product" element={<MerchAllProduct />} />
          <Route path="/launched-sku" element={<AllLaunchedProduct />} />
          <Route path="/EditMerchEntry" element={<MerchEntry />} />
        </Route>

        {/* Planning WorkSpace*/}
        <Route element={<RequiredAuth allowedRoles={[1000, 1008, 1007]} />}>
          <Route path="/planning-new-prod" element={<PlanningNewProd />} />

          <Route
            path="/planning-complete-product"
            element={<PlanningCompletedProducts />}
          />

          <Route path="/EditPlaningEntry" element={<PlanningEntry />} />
        </Route>

        {/* Creative WorkSpace*/}
        <Route element={<RequiredAuth allowedRoles={[1000, 1008, 1007]} />}>
          <Route path="/creative-new-prod" element={<CreativeNewProd />} />

          <Route
            path="/creative-complete-product"
            element={<CreativeCompletedProduct />}
          />

          <Route path="/EditPlaningEntry" element={<CreativeEntry />} />
        </Route>

        {/* Ecommerce WorkSpace*/}
        <Route element={<RequiredAuth allowedRoles={[1000, 1002, 1006]} />}>
          <Route path="/ecom-new-product" element={<EcomNewProduct />} />
          <Route
            path="/ecom-completed-product"
            element={<EcomCompletedProduct />}
          />
          <Route path="/launch-details" element={<LaunchDetails />} />
          <Route path="/ecom-all-prod" element={<EcomAllProduct />} />
          <Route path="/generateSheets" element={<GenerateSheets />} />
          <Route path="/direct-upload-spec" element={<UplaodSheet />} />
          <Route path="/TestPageView" element={<TestPage />} />
        </Route>

        {/* Launch Process management */}
        <Route
          element={
            <RequiredAuth
              allowedRoles={[1000, 1001, 1003, 1002, 1004, 1007, 1008]}
            />
          }
        >
          <Route path="/seesion-management" element={<SessionManagement />} />
          <Route path="/launchMangement" element={<LaunchMaster />} />
          <Route
            path="/postpone-products-management"
            element={<PostponeManagementPortal />}
          />
          <Route
            path="/product-details-view"
            element={<ProductDetailsView />}
          />
        </Route>

        {/* Masters */}
        <Route
          element={
            <RequiredAuth
              allowedRoles={[1000, 1001, 1003, 1002, 1004, 1007, 1008]}
            />
          }
        >
          <Route path="/countryMaster" element={<CountryMaster />} />
          <Route path="/stateMaster" element={<StateMaster />} />
          <Route path="/cityMaster" element={<CityMaster />} />
          <Route path="/fabricMaster" element={<FabricMaster />} />
          <Route path="/indentMaster" element={<IndentMaster />} />
          <Route path="/techpackMaster" element={<TechpackMaster />} />
          <Route path="/ProductOptionType" element={<ProductOptionType />} />
          <Route path="/pp_bracket" element={<PPBracketMaster />} />
          <Route path="/ColorMaster" element={<ColorMaster />} />
          <Route
            path="/variantProductDetail"
            element={<VariantProductDetails />}
          />
          <Route path="/sizeMaster" element={<SizeMaster />} />
          <Route path="/patternMaster" element={<PatternMaster />} />
          <Route
            path="/specialAttributesMaster"
            element={<SpecialAttributeMaster />}
          />
          <Route path="/silhouetteMaster" element={<SilhouetteMaster />} />
          <Route
            path="/unitOfMeasurementMaster"
            element={<UnitOfMeasurementMaster />}
          />
          <Route
            path="/productSubCategoryMaster"
            element={<ProductSubCategoryMaster />}
          />
          <Route path="/generateBuyPlan" element={<GenerateBuyPlan />} />
          <Route path="/generateSpecSheet" element={<GenerateSpecSheet />} />
        </Route>

        <Route
          element={
            <RequiredAuth
              allowedRoles={[1000, 1001, 1003, 1002, 1004, 1007, 1008, 1009]}
            />
          }
        >
          <Route path="/supplierMaster" element={<SupplierMaster />} />
          <Route path="/supplierDetails" element={<SupplierDetails />} />
        </Route>

        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
